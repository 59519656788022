import { deleted, get, post } from '@/utils/request'
import type { AxiosRequestConfig } from 'axios'
// 期刊卡片模块数据
export const getJournalList = async (data: AxiosRequestConfig) => {
  const response = await get('/info/journalInstantInfo/listVo', data)
  return response
}
export const getJournalHome = async (data: AxiosRequestConfig) => {
  const response = await get('/info/journal/home', data)
  return response
}
//期刊详情卡片模块数据
export const getJournalInfo = async (id: string) => {
  const response = await get(`/info/journalInstantInfo/${id}`)
  return response
}
export const getImpactFactorList = async (data?: AxiosRequestConfig) => {
  const response = await get('info/impactFactor/list', data)
  return response
}
export const getCasJcrList = async (data?: AxiosRequestConfig) => {
  const response = await get('/info/casJcr/listAll', data)
  return response
}
export const getFreePaperList = async (data?: AxiosRequestConfig) => {
  const response = await get('/info/freePaper/listVo', data)
  return response
}
// 获取因子趋势图数据
export const getTendency = async (data?: AxiosRequestConfig) => {
  const response = await get('/info/impactFactor/getFiveYearsData', data)
  return response
}
// 获取citeScore趋势图数据
export const getCiteScore = async (data?: AxiosRequestConfig) => {
  const response = await get('/info/journalScopus/getScopusByJournalId', data)
  return response
}
// 获取中科院趋势图数据
export const getCitation = async (data: any) => {
  const response = await get('/info/journalCas/getFiveYearsData', data)
  return response
}
// 获取自引率趋势图数据
export const getSelfCite = async (data: any) => {
  const response = await get('/info/selfCite/getFiveYearsData', data)
  return response
}
// 获取年发文趋势图数据
export const getDispatch = async (data: any) => {
  const response = await get('/info/review/getFiveYearsData', data)
  return response
}
// 获取OA趋势图数据
export const getOaArticle = async (data: any) => {
  const response = await get('/info/openAccess/getFiveYearsData', data)
  return response
}

// 获取期刊主数据
export const getMainInfo = async (data: any) => {
  const response = await get('/info/journalMain/mainInfo', data)
  return response
}

// 获取中科院详情
export const getCollegeInfo = async (data: any) => {
  const response = await get('/info/journalCasInfo/getNewestInfoByJournalId', data)
  return response
}

// 获取领域数据
export const getTerritoryInfo = async (data: any) => {
  const response = await get('/info/journalCas/getNewestCas', data)
  return response
}

// 获取中科院预警数据
export const getWarningInfo = async (data: any) => {
  const response = await get('/info/journalCasWarning/listAll', data)
  return response
}

// 获取即时IF数据
export const getNowIfInfo = async (data: any) => {
  const response = await get('/info/impactFactorNow/getTenMonthImpactFactorNow', data)
  return response
}

// 获取完成度数据
export const getCompleteInfo = async (data: any) => {
  const response = await get('/info/completeScale/getTenMonthCompleteScale', data)
  return response
}

// 获取五年影响因子数据
export const getDivisorInfo = async (data: any) => {
  const response = await get('/info/review/getFiveYearsData', data)
  return response
}

// 获取综述占比数据
export const getReviewInfo = async (data: any) => {
  const response = await get('/info/review/getNewestReviewScale', data)
  return response
}

// 获取国人占比数据
export const getCountrymenInfo = async (data: any) => {
  const response = await get('/info/chineseScale/getChineseScale', data)
  return response
}

// 获取自引率数据
export const getCitingInfo = async (data: any) => {
  const response = await get('/info/selfCite/getNewSelfCite', data)
  return response
}

// 获取cjr数据
export const getJirInfo = async (data: any) => {
  const response = await get('/info/journalJcr/getJcrQuartile', data)
  return response
}

// 获取Scopus数据
export const getScopusInfo = async (data: any) => {
  const response = await get('/info/journalScopus/getJournalDetailScopus', data)
  return response
}

// 获取CiteScore数据
// export const getCiteScoreInfo = async (data:any) => {
//   const response = await get('/info/journalScopus/getFiveYearsData', data)
//   return response
// }

// 获取期刊jcr数据
export const getJournalJcrInfo = async (data: any) => {
  const response = await get('/info/journalJcr/getJcrInfo', data)
  return response
}

// 获取sacc数据
export const getSaccInfo = async (data: any) => {
  const response = await get('/info/journalSacc/getSaccByJournalId', data)
  return response
}

// 获取发文领域数据
export const getWosInfo = async (data: any) => {
  const response = await get('/info/journalWos/getCitationTopicsMicroInfo', data)
  return response
}

// 获取发文机构数据
export const getJournalWos = async (data: any) => {
  const response = await get('/info/journalWos/getAffiliationsInfo', data)
  return response
}

export const getDocumentsTypes = async (data: any) => {
  const response = await get('/info/journalWos/getDocumentsTypes', data)
  return response
}

export const getJournalWos3 = async (data: any) => {
  const response = await get('/info/journalWos/getCountriesRegionsInfo', data)
  return response
}
export const getSameField = async (data: any) => {
  const response = await get('/info/journal/sameField', data)
  return response
}
// 年发文量趋势图，国人占比趋势图，和oa趋势图
export const getGather = async (id: any) => {
  const response = await get(`/info/journal/wos/${id}`)
  return response
}
// 月即时if和月完成度
export const getMonth = async (data: any) => {
  const response = await get(`/info/journal/month/chart`, data)
  return response
}
// 查询所有出版社

export const getPressAll = async () => {
  const response = await get(`/info/press/all`)
  return response
}

// 期刊对比添加
export const compareAdd = async (data: any) => {
  const response = await post('/info/journal/comparison', data)
  return response
}
// 期刊对比删除
export const compareDelete = async (data: any) => {
  const response = await deleted(`/info/journal/comparison/${data}`)
  return response
}
// 获取期刊对比
export const getCompare = async () => {
  const response = await get(`/info/journal/comparison`)
  return response
}
// 期刊成功案例
export const getSuccessCase = async (data: any) => {
  const response = await get(`/info/successCase`, data)
  return response
}
export const getPeerReview = async (data: any) => {
  const response = await get('/info/peer/review/query', data)
  return response
}
