// 针对keep-alive做缓存规则

// 指定不被缓存的页面
export const excludeRule = [
  'user-login', //登录页
  'user-register', //注册页
  'search-practical-info', //期刊详情
  'search-country-nature-info', //国自然详情
  'user-personal-edit' //编辑资料
  // 'user-personal' //个人中心
  // 'register',//注册页
  // 'login',//登录页
  // 'practical-info',//期刊详情
  // 'country-nature-info',//国自然详情
  // 'personal-edit',//编辑资料
  // 'personal',//个人中心
]
