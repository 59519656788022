import * as practicalApi from '@/services/practical'
import { compareAdd, compareDelete, getCompare } from '@/services/practical'
import { useUserStore } from '@/store/user'
import { defineStore } from 'pinia'
import type { PersistenceOptions } from 'pinia-plugin-persistedstate'
import { watch } from 'vue'

// 定义自定义事件 用于通知期刊对比 数据更新 更新视图
const DATA_FETCHED_EVENT = new CustomEvent('dataFetched')

// 定义 Iitem 类型
interface Iitem {
  id: string | number
  journalId?: string | number
  journalPress?: string
  isReveal?: boolean
  wos?: any
  score?: any
  cite?: any
  month?: any
  Ifs?: any
  journalName?: string
  journalImage?: string
  name?: string
  isLocked?: boolean
}

export const usePeriodicalItem = defineStore<any, any, any>('periodicalItem', {
  state: () => {
    return {
      items: [] as Iitem[], //选择列表
      activeList: [] as Iitem[], //已选列表
      promise: undefined,
      gotoActive: false, //记录跳转
      showChartValues: true, //是否显示图表值
      showChart: false //是否显示图表
    }
  },
  getters: {
    // 返回已锁定的期刊数量
    lockedLength: (state: {
      items: { filter: (arg0: (item: any) => boolean) => { (): any; new (): any; length: any } }
    }) => {
      return state.items.filter((item: { isLocked: boolean }) => item.isLocked === true).length
    }
  },
  actions: {
    // 获取服务端期刊对比列表 返回已锁定期刊
    async getCompare() {
      const newItems = await getCompare()
      console.log(newItems, '---newItems')
      newItems.forEach((item: Iitem) => {
        item.isLocked = true
      })

      // 合并数组，保持newItems优先，并从this.items中删除重复项
      this.items = [
        ...newItems,
        ...this.items.filter(
          (item: { journalId: string }) =>
            !newItems.some((newItem: Iitem) => newItem.journalId === item.journalId)
        )
      ]
    },
    // 改变锁定状态
    changeLocked(item: Iitem) {
      if (item.isLocked) {
        item.isLocked = false
        this.delCompare(item.journalId)
      } else {
        item.isLocked = true
        this.addCompare(item)
      }
    },
    // 修改跳转状态
    upGotoActive(val: boolean) {
      this.gotoActive = val
    },
    // 添加同步后端记录
    async addCompare(item: Iitem) {
      await compareAdd({
        journalId: item.journalId
      })
    },
    // 删除同步后端记录
    async delCompare(id: string) {
      await compareDelete(id)
    },
    // 期刊对比里添加项 不需要排序
    infoAddItems(item: Iitem) {
      this.addItems(item, false)
      this.activeList.push(item)
      this.updateSort()
    },
    // 添加一项
    async addItems(Item: Iitem, isSort: boolean = true) {
      const userStore = useUserStore()

      // 不存在则添加
      if (!this.items.some((item: Iitem) => item.journalId === Item.journalId)) {
        // 创建深拷贝
        const newItem = JSON.parse(JSON.stringify(Item))
        newItem.isReveal = this.activeList.length < 4 && userStore.isLogin
        this.items.unshift(newItem)
        if (!isSort) return
        this.addActiveListOne(newItem as Iitem)
      }
    },
    // 列表前4项添加已选
    addActiveListOne(obj: Iitem) {
      const userStore = useUserStore()
      if (this.activeList.length < 4 && userStore.isLogin) {
        this.activeList.unshift(obj)
      }
      // 对 items 数组进行排序，isReveal 为 true 的项排在前面
      this.updateSort()
    },
    // 更新高亮状态
    // this.highlight()
    // this.items.map((item, index) => {
    //   if (index < 4) {
    //     this.addActiveList(index, item)
    //   }
    // })
    // // 追加出版商
    // getMainInfoList() {
    //   this.items.forEach(async (item) => {
    //     if (item.journalId && !item.journalPress) {
    //       const response = await practicalApi.getMainInfo({ params: { journalId: item. journalId } })
    //       item.journalPress = response.data.journalPress
    //     }
    //   })
    // },
    // 删除一项
    delItems(obj: Iitem) {
      this.items = [
        ...this.items.filter((item: { journalId: string }) => item.journalId !== obj.journalId)
      ]
      this.activeList = [
        ...this.activeList.filter((item: { journalId: string }) => item.journalId !== obj.journalId)
      ]
      if (obj.isLocked) {
        this.delCompare(obj.journalId)
      }
    },
    // 清空 保留锁定项
    allDelItems() {
      this.items = [...this.items.filter((item: { isLocked: boolean }) => item.isLocked)]
      this.activeList = [...this.items.filter((item: { isReveal: boolean }) => item.isReveal)]
    },
    // 添加已选 列表数据里勾选
    addActiveList(item: Iitem) {
      this.items.forEach((i: { journalId: string; isReveal: boolean }) => {
        if (i.journalId === item.journalId) {
          i.isReveal = true
        }
      })
      this.activeList.push(item)
    },

    // 取消勾选对比项
    delActiveList(id: string, isSort: boolean = true) {
      // 删除选中列表中项
      const index = this.activeList.findIndex(
        (item: { journalId: string }) => item.journalId === id
      )
      if (index !== -1) {
        this.activeList.splice(index, 1)
      }
      // 对比列表取消勾选
      this.items.forEach((item: { journalId: string; isReveal: boolean }) => {
        if (item.journalId === id) {
          item.isReveal = false
        }
      })
      if (!isSort) return
      // 重新排序
      this.updateSort()
    },
    // 高亮
    highlight() {
      // 创建一个 Set 来存储 b 中的 id 以提高查找效率
      const bIds = new Set(this.activeList.map((item: { journalId: string }) => item.journalId))

      // 遍历 a 数组并更新 isReveal 属性
      for (const item of this.items) {
        item.isReveal = bIds.has(item.journalId)
      }
    },
    // 更新排序
    updateSort() {
      this.items = this.sortItems(this.items)
    },
    // 列表排序
    sortItems(arr: any[]) {
      return arr.sort((item1: { isReveal: boolean }, item2: { isReveal: boolean }) => {
        const isReveal1 = item1.isReveal === true ? 1 : item1.isReveal === false ? 0 : -1
        const isReveal2 = item2.isReveal === true ? 1 : item2.isReveal === false ? 0 : -1
        return isReveal2 - isReveal1 // 使得 true 的项排在前面
      })
    },
    //已选排序
    sort() {
      // 清除空对象
      this.activeList = this.activeList.filter((item: {}) => Object.keys(item).length !== 0)

      // 在数组末尾添加一个空对象
      while (this.activeList.length < 4) {
        this.activeList.push({})
      }
    },
    // 追加activeList数据
    addActiveListWos(item: Iitem, index: number) {
      this.activeList[index].wos = item
    },
    addActiveListScore(item: Iitem, index: number) {
      this.activeList[index].score = item
    },
    addActiveListCite(item: Iitem, index: number) {
      this.activeList[index].cite = item
    },
    addActiveListMonth(item: Iitem, index: number) {
      this.activeList[index].month = item
    },
    addActiveListIf(item: Iitem, index: number) {
      this.activeList[index].Ifs = item
    },
    async promiseWos() {
      this.promise = await Promise.allSettled(
        this.activeList.map(async (element: { journalId: any }, index: any) => {
          if (element.journalId) {
            // if
            const responseIf = await practicalApi.getTendency({
              params: { journalId: element.journalId }
            })

            this.addActiveListIf(responseIf, index)
            // 即时if   完成度
            const responseMonth = await practicalApi.getMonth({
              params: { journalIds: element.journalId }
            })
            this.addActiveListMonth(responseMonth, index)
            // 年发文量趋势图，国人占比趋势图，和oa趋势图
            const response = await practicalApi.getGather(element.journalId)
            this.addActiveListWos(response, index)
            // 获取citeScore趋势图数据
            const responseScore = await practicalApi.getCiteScore({
              params: { journalId: element.journalId }
            })
            this.addActiveListScore(responseScore, index)
            // 获取自引率趋势图数据
            const responseCite = await practicalApi.getSelfCite({
              params: { journalId: element.journalId }
            })
            this.addActiveListCite(responseCite, index)
          }
        })
      )
    },
    // 清除持久化数据
    clearPersistedData() {
      localStorage.removeItem('periodical-compare')
      this.items = []
      this.activeList = []
      this.showChartValues = true
      this.showChart = false
      this.gotoActive = false
      this.promise = undefined
    },
    // 封装单个项的数据请求逻辑
    async fetchItemData(element: Iitem, index: number) {
      if (!element.journalId) return

      // 将所有请求放入数组中并行执行
      const promises = [
        // IF趋势
        practicalApi
          .getTendency({
            params: { journalId: element.journalId }
          })
          .then((response) => this.addActiveListIf(response, index)),

        // 即时IF完成度
        practicalApi
          .getMonth({
            params: { journalIds: element.journalId }
          })
          .then((response) => this.addActiveListMonth(response, index)),

        // 年发文量趋势图等
        practicalApi
          .getGather(element.journalId)
          .then((response) => this.addActiveListWos(response, index)),

        // CiteScore趋势图
        practicalApi
          .getCiteScore({
            params: { journalId: element.journalId }
          })
          .then((response) => this.addActiveListScore(response, index)),

        // 自引率趋势图
        practicalApi
          .getSelfCite({
            params: { journalId: element.journalId }
          })
          .then((response) => this.addActiveListCite(response, index))
      ]

      // 等待所有请求完成后触发事件
      await Promise.all(promises)

      window.dispatchEvent(DATA_FETCHED_EVENT)
    },
    // 添加新的 action来监听活动期刊
    setupWatcher() {
      watch(
        () => this.activeList.length,
        (newLength, oldLength) => {
          // 只在长度增加时处理
          if (newLength > oldLength) {
            // 获取最新添加的项
            const newItem = this.activeList[this.activeList.length - 1]

            if (newItem?.journalId) {
              this.fetchItemData(newItem, this.activeList.length - 1)
            }
          } else {
            // 使用顶层定义的事件
            window.dispatchEvent(DATA_FETCHED_EVENT)
          }
        }
      )
    }
  },
  persist: {
    key: 'periodical-compare',
    storage: localStorage,
    paths: ['items', 'activeList']
  } as PersistenceOptions
})
