// 中科院缓存
import { defineStore } from 'pinia'

export const useChineseAcademyStore = defineStore('chineseAcademy', {
  state: () => {
    return {
      list: null as null | any[],
      subjectFieldList: null as null | any[] //期刊高级检索小类列表
    }
  }
})
