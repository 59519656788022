import './assets/main.less'

import Footer from '@/components/Footer/index.vue'
import { reportError } from '@/services/system'
import '@/utils/moment'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import 'intersection-observer'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as stackTraceParser from 'stacktrace-parser'
import { createApp } from 'vue'
import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import { BASE_IMAGE_URL } from './constants'
import cancelImgDragMixins from './mixins/cancel-img-drag'
import router from './router'
import { px2rem } from './utils/tools'

const app = createApp(App)
//全局挂载图片链接前缀
app.config.globalProperties.$BASE_IMAGE_URL = BASE_IMAGE_URL
//全局挂载px转rem方法 (解决内联样式单位无法转换 需要手动转换)
app.config.globalProperties.$px2rem = px2rem

// 测试不上报
if (import.meta.env.VITE_API_BASEURL !== '/api') {
  // 捕获框架内部错误
  app.config.errorHandler = function (err: any, vm, info) {
    const stack = {
      name: err.name,
      message: err.message,
      stack: err.stack
    }
    reportError({
      stack,
      source: stackTraceParser.parse(err.stack)[0],
      appInfo: info
    })
    // const errInfo = stackTraceParser.parse(err.stack)[0]
    // const message = err.message // 错误message
    // const lineno = errInfo.lineNumber // 错误行数
    // const colno = errInfo.column // 错误列数
    // const source = errInfo.file // 错误出现的文件名
  }

  // 捕获js报错
  window.onerror = function (message, source, lineno, colno) {
    reportError({
      stack: {
        message,
        stack: source
      },
      source: {
        column: colno,
        lineNumber: lineno
      }
    })
  }
}

app.component('xzc-footer', Footer)
app.mixin(cancelImgDragMixins)
const pinia = createPinia()
app.use(pinia)
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  observer: true
})
pinia.use(piniaPluginPersistedstate)
app.use(router)
app.use(Antd)
app.mount('#app')
